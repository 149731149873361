import React, { useState } from "react";
import Layout from "../organisms/Layout";
import Wrapper from "../atoms/Wrapper";
import Container from "../atoms/Container";
import Text from "../atoms/Text";
import { PageSEO } from "../atoms/SEO";

type PROPS_STYLE = {
  location: any;
};

export default function Terms(props: PROPS_STYLE) {
  const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal

  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    // setShowWaitListForm(true);
    window.open("https://form.jotform.com/240982279984475", "_blank");
  }

  function hideDisplayWaitListForm() {
    //hide the wait-list modal/form by setting showWaitListFrom to false
    setShowWaitListForm(false);
  }

  return (
    <Layout
      //@ts-ignore
      hideDisplayWaitListForm={hideDisplayWaitListForm}
      showWaitListForm={showWaitListForm}
      displayWaitListForm={displayWaitListForm}
    >
      <PageSEO
        path={props.location.pathname}
        title={"Terms & Conditions - SaaSPay"}
        description={
          "SaaSPay - Terms & Conditions. Read about the terms and conditions that are to be abided by the users for the b2b saas financing services offered."
        }
      />

      <Wrapper>
        <Container className={"terms py-20 px-4 xl:px-0"}>
          <div className={"text-center my-[60px] "}>
            <Text text={"Terms & Conditions"} textStyle={"h1"} />
          </div>
          <Text
            component={"h1"}
            text={"These Terms of Use (“Terms”) was last updated on 13-02-2023"}
            textStyle={"h4"}
          />
          <br />
          <br />
          <div className={"text-justify text-[#323B4B] open-400-16-28 mb-10"}>
            This document is an electronic record as per the Information
            Technology Act, 2000, the rules thereunder, and other statues as
            amended from time to time pertaining to electronic records by the
            Information Technology Act, 2000. This electronic record is
            generated by a computer system and does not require any physical or
            digital signatures.
            <br />
            <br />
            Our website https://www.saaspay.com/ and other related software
            and/or internet based applications (collectively referred to as
            "Application(s)") are owned and operated by SaaSPay Private Limited
            (“<b>Company</b>”) is a private limited company registered under the
            Companies Act, 2013 and having its registered office in Bengaluru,
            Karnataka. “SaaSPay” is a registered trademark of the Company. You
            are requested to read these terms before registering, accessing,
            browsing, downloading or using anything from the Application.
            <br />
            <br />
            <b>Declaration:</b>
            <br />
            <br />
            By accessing or using the Service, You agree to be bound by these
            Terms. If You disagree with any part of these Terms, then You may
            not access the Service.
            <br />
            <br />
            You represent that You are (i) competent to contract as per the
            Indian Contract Act, 1872 (ii) a person resident in India as defined
            under Foreign Exchange Management Act, 1999, (iii) accepting the
            terms and conditions of these Terms and the Privacy Policy as
            displayed on the Application and (iv) consenting to receive
            communications, notices and information from Us through post or
            other physical medium or electronically (whether through SMS,
            emails, phone calls and automated phone calls).
            <br />
            <br />
            You hereby understand and agree that when you use the Services,
            SaaSPay, one or more of the Partners or their authorised
            representatives may check your records, amongst other things, with
            their own records, and any information as available to SaaSPay or
            the Partners through any of its sources, including those at credit
            information companies, fraud prevention agencies for assessing your
            credit worthiness, verifying identity to prevent and detect crime
            and money laundering.
            <br />
            <br />
            <h3>Part 1: Your Terms of Use</h3>
            The following terms shall be applicable to You.
            <br />
            <br />
            <b>Definitions:</b>
            <br />
            <br />
            <b>Application</b> shall mean the website https://www.saaspay.com/
            and any other related web based or mobile based application owned
            and developed by the Company.
            <br />
            <b>Service</b> shall mean the service of connecting You with the
            Partners for the purpose of availing a financial facility to buy or
            subscribe to a SaaS Product from the Service Provider.
            <br />
            <b>Partners</b> shall mean any bank or financial institution that
            provides the financial service of credit to You.
            <br />
            <b>SaaS Product</b> shall mean the product offering being purchased
            by you using the service provider.
            <br />
            <b>Service Provider</b> shall mean the person who sells or licenses
            the SaaS Product to You.
            <br />
            <b>You/Your/Yourself</b> shall mean the user of the Application as
            approved by SaaSPay.
            <br />
            <br />
            <b>Service:</b>
            <br />
            <br />
            SaaSPay’s Application enables You to connect with one or more banks,
            financial institutions or other entities ("<b>Partners</b>") that
            SaaSPay has partnered with to provide financial facility for
            purchase or subscription of SaaS Products provided by third party
            service providers ("<b>Service Provider</b>").
            <br />
            <br />
            SaaSPay shall endeavour to facilitate your transaction with a
            Service Provider to subscribe to and/or purchase the SaaS Products
            provided by such Service Providers. However, SaaSPay also reserves
            the right to change the nature of Services provided from time to
            time or completely stop providing the Service without any prior
            notice.
            <br />
            <br />
            By using the Application, You hereby acknowledge that neither is
            SaaSPay a Service Provider nor are the SaaS Products being provided
            by it. SaaSPay only facilitates the financial facility to You to
            subscribe and/or purchase the SaaS Products from the Service
            Providers, thus enabling better cash allocation for You.
            <br />
            <br />
            Consequently, any liability therefrom shall be governed by the
            agreement that shall be entered into between You and the Service
            Provider. SaaSPay shall have no privity of contract in relation to
            the said agreement between You and the Service Provider. You further
            agree to release and SaaSPay also waives all its liability in
            relation to the SaaS Products and any transaction between You and
            the Service Provider.
            <br />
            <br />
            SaaSPay may collect and hold any documents, writings, agreements,
            cheques, drafts, other instruments received from You relating to the
            payments for the SaaS Products, in trust and as agent for the
            Service Provider, as the case may be. It shall also maintain
            necessary records in respect of the transactions entered into by You
            with the Service Provider and amounts due in respect of SaaS
            Products and You agree to sharing of such records by SaaSPay with
            the Partner or the Service Provider in order to facilitate the
            Service.You agree to not dispute the said records.
            <br />
            <br />
            You may stop using the Services, without notice, at any time,
            provided that amounts that are due from you to SaaSPay under these
            Terms are completely settled by You.
            <br />
            <br />
            <h4>Creating and maintaining of Accounts</h4>
            You may receive an email with the invoice created by the Service
            Provider along with a call-to-action in the email which directs You
            to the Application. You may be required to authenticate Yourself
            with Your email ID and an OTP verification. Subsequently, SaaSPay
            may collect information like name, email ID, phone number and
            business name, company domain, annual revenue, entity type, GSTIN,
            CIN, DIN, etc.
            <br />
            <br />
            You may also be required to complete a KYC and KYB process by
            providing access too the Aadhar Number, phone and PAN of Your
            director. SaaSPay may also collect relevant user data from the
            Service providers to facilitate the Service efficiently. The
            collection, verification, audit and maintenance of correct and
            updated information is a continuous process and SaaSPay reserves the
            right, at any time, to take steps necessary to ensure Your
            compliance with all relevant and applicable KYC requirements, if
            any.
            <br />
            <br />
            You represent and warrant that all information provided by you is
            correct and accurate. No information is false or untrue. Amongst
            other remedies available to SaaSPay, SaaSPay reserves the right to
            terminate Services immediately if any of the information provided by
            you is untrue.
            <br />
            <br />
            User account bearing details provided by the You are created and
            owned by SaaSPay. Any promotional discounts, offers and reward
            points accumulated or likes of such provided by the Service
            providers can be revoked without prior notice in the event of
            suspicious account activity or malafide intent/conduct. In the case
            where the system is unable to establish Your unique identity against
            the details provided to SaaSPay, the account shall be indefinitely
            suspended. SaaSPay reserves the full discretion to suspend Your
            account in the above event and does not have the liability to share
            any account information whatsoever.
            <br />
            <br />
            <h4>UIDAI consent</h4>
            <i>
              For the purposes of this clause, You shall mean the individual
              whose Aadhar card is being provided.
            </i>
            <br />
            <br />
            By using the Services, You confirm that you have read the Privacy
            Policy and these Terms hereunder and that you provide your
            unconditional consent to SaaSPay and its authorised representatives
            for collecting and processing your personal data for the purposes of
            providing the Services and completing the KYC and KYB processes.
            Also, please note that you can withdraw your consent to all or any
            of the purposes at any time by{" "}
            <i>
              sending an email to{" "}
              <a href={"mailto:support@saaspay.app"}>support@saaspay.app</a>
            </i>
            . If you withdraw consent to such processing, we will stop
            processing your personal data however, any personal data already
            been processed shall remain unaffected by your withdrawal of
            consent.
            <br />
            <br />
            <u>Data we collect</u>: We may collect and maintain certain personal
            data about You. The personal data collected may include but is not
            limited to:
            <br />
            You may add Identity Information, Educational details, Professional
            Details, Health Information, etc.
            <br />
            <br />
            <u>Purpose[s] of processing</u>:&nbsp; SaaSPay or its authorised
            representatives keep and process information about you to{" "}
            <i>
              further enhance your experience on Portal, and provide certain
              services that cannot be accessed by all users of the SaaSPay
              application
            </i>
            . The information may be used by to the Credit Information Bureau of
            India (CIBIL), EXPERIAN SERVICES INDIA and/or any other
            governmental/regulatory/statutory or private agency/ entity, credit
            bureau, RBI, the Lender’s other branches/ subsidiaries / affiliates
            / rating agencies, service providers, banks / financial
            institutions, any third parties (LMS provider, Data Access
            Platforms) , any assignees/potential assignees or transferees, who
            may need the information and may process the information for
            providing the Services
            <br />
            <br />
            We may share your personal data with financing partners or where
            required or permitted by law.
            <br />
            <br />
            <h4>Access and Communication</h4>
            By availing the Services, you expressly consent to be contacted by
            SaaSPay, its representatives, affiliates, or anyone calling on its
            behalf at any contact number, or physical or electronic address
            provided by You while registering your account with SaaSPay. You
            further agree to us contacting you in any manner, including without
            limitation, emails, SMS messages, WhatsApp, calls using pre-recorded
            messages or artificial voice, and notifications sent via our mobile
            application.
            <br />
            <br />
            In our endeavour to effectively deliver the Services, You provide
            your consent to SaaSPay, the Partners and their authorised
            representatives to access Your bank statements and other records in
            connection with providing the Services requested by You. To the
            extent required for provision of the Services, any
            password-protected document may be accessed by utilizing information
            provided by you to automatically generate passwords in order to
            safely access such records. It is further clarified that the consent
            sought from You shall be used solely in connection with providing
            the Services and such information shall not be shared with any third
            party, save and except with relevant Partners who may require the
            aforementioned information.
            <br />
            <br />
            <h4>Payment model:</h4>
            If the KYC and KYB verification is complete to the satisfaction of
            SaaSPay, the Partner and the Service Provider, You will have the
            option to select the payment model as shall be visible on the
            dashboard that maybe made available to You from time to time. You
            may be required to connect and active the eNach facility to Your
            bank account for periodic debit of amounts as agreed between You and
            the Partner.
            <br />
            <br />
            <h3>Part 2: Service Provider Terms of Use</h3>
            The following terms shall be applicable to the Service Provider.
            <br />
            <br />
            <h4>Creating and maintaining of Accounts</h4>
            On the application, the Service provider may provide the requested
            information to SaaSPay and upon adequate satisfaction of its
            credibility, the Application may direct the Service Provider to
            authenticate itself with email ID and an OTP verification.
            Subsequently, SaaSPay may collect information like name, email ID,
            phone number and business name, company domain, bank account
            details, annual revenue, entity type, GSTIN, CIN, DIN, etc. SaaPay
            may, either by itself or through an authorised third party conduct a
            KYC and KYB process for which relevant maybe sought and the Service
            Provider shall have to provide the same.
            <br />
            <br />
            You may also have the option of listing the SaaS Products on the
            Application and also for generation of invoice.
            <br />
            <br />
            The collection, verification, audit and maintenance of correct and
            updated information is a continuous process and SaaSPay reserves the
            right, at any time, to take steps necessary to ensure compliance
            with all relevant and applicable KYC requirements, if any.
            <br />
            <br />
            The Service provider represents and warrants that all information
            provided is correct and accurate. No information is false or untrue.
            Amongst other remedies available to SaaSPay, SaaSPay reserves the
            right to terminate Services immediately if any of the information
            provided is untrue.
            <br />
            <br />
            User account bearing details provided by the Service Provider are
            created and owned by SaaSPay. Any promotional discounts, offers and
            reward points accumulated or likes of such provided by the Service
            providers can be revoked without prior notice in the event of
            suspicious account activity or malafide intent/conduct of the
            Service Provider. In the case where the system is unable to
            establish unique identity of the Service Provider against the
            details provided to SaaSPay, the account shall be indefinitely
            suspended. SaaSPay reserves the full discretion to suspend a Service
            Provider ‘s account in the above event and does not have the
            liability to share any account information whatsoever.
            <br />
            <br />
            <h3>Part 3 : General Terms of Use</h3>
            <br />
            The following terms shall be applicable to You and the Service
            Provider. For the purposes of this Part – 3, You shall mean ‘You’
            and the ‘Service Provider”.
            <br />
            <br />
            Restrictive Covenants:
            <br />
            <br />
            While accessing the Application, You agree not to or enable others
            to:
            <br />
            <br />
            - Carry on any unlawful or promoting unlawful activity
            <br />- Issue any defamatory, discriminatory, or mean-spirited
            content, including references or commentary about religion, race,
            sexual orientation, gender, national/ethnic origin, or other
            targeted groups.
            <br />- Spam, machine – or randomly – generated, constituting
            unauthorized or unsolicited advertising, chain letters, any other
            form of unauthorized solicitation, or any form of lottery or
            gambling.
            <br />- Install any viruses, worms, malware, trojan horses, or other
            content that is designed or intended to disrupt, damage, or limit
            the functioning of any software, hardware or telecommunications
            equipment or to damage or obtain unauthorized access to any data or
            other information of a third person.
            <br />- Infringing on any proprietary rights of any party, including
            patent, trademark, trade secret, copyright, right of publicity or
            other rights.
            <br />- Impersonating any person or entity including SaaSPay and its
            employees or representatives.
            <br />- authorize others to use your account;
            <br />- assign or otherwise transfer Your account to any third
            person or legal entity;
            <br />- copy, decompile, reverse engineer, disassemble, attempt to
            derive the source code of, decrypt, modify, or create derivative
            works of SaaSPay or any services provided by SaaSPay, or any part
            thereof
            <br />- disclose information designated as confidential by SaaSPay,
            the Partner or Service provider without their prior written consent.
            <br />- Please note that refunds and cancellations are not
            available, as per our terms and conditions.
            <br />
            <br />
            <h4>Limitation of liability:</h4>
            All content and services provided on the Application are provided on
            an “as is” and “as available” basis. SaaSPay expressly disclaims all
            warranties of any kind, whether express or implied, including, but
            not limited to, the implied warranties of merchantability, fitness
            for a particular purpose, title, non-infringement, and security and
            accuracy, as well as all warranties arising by usage of trade,
            course of dealing, or course of performance. SaaSPay makes no
            warranty, and expressly disclaims any obligation, that: (a) the
            content will be up-to-date, complete, comprehensive, accurate or
            applicable to your circumstances; (b) the website will meet Your
            requirements or will be available on an uninterrupted, timely,
            secure, or error-free basis; (c) the results that may be obtained
            from the use of the website or any services offered through the site
            will be accurate or reliable; or (d) the quality of any products,
            services, information, or other material obtained from you through
            the site will meet your expectations.
            <br />
            <br />
            SaaSPay (including its officers, directors, employees,
            representatives, affiliates, and providers) will not be responsible
            or liable for (a) any injury, death, loss, claim, act of god,
            accident, delay, or any direct, special, exemplary, punitive,
            indirect, incidental or consequential damages of any kind (including
            without limitation lost profits or lost savings), whether based in
            contract, tort, strict liability or otherwise, that arise out of or
            is in any way connected with (i) any failure or delay (including
            without limitation the use of or inability to use any component of
            the website), or (ii) any use of the website, Application or
            content, or (iii) the performance or non-performance by SaaSPay or
            any provider or (b) any damages to or viruses that may infect your
            computer equipment or other property as the result of your access to
            the website or your downloading of any content from the website.
            <br />
            <br />
            SaaSPay shall also not be responsible for any action o inaction,
            representation or warranty provided by the Partners or the Service
            Provider or in relation to the SaaS Product. Notwithstanding
            anything to the contrary, the liability of SaaSPay shall be limited
            to Rs. 100/- cumulatively for all causes of action that You may have
            against SaaSPay.
            <br />
            <br />
            Any decision in relation to credit limits, decision to provide the
            financial credit, etc. is the decision of the Partners and the
            Service provider. You agree to not hold SasPay liable if any Partner
            does not make credit available to You.
            <br />
            <br />
            <h4>Intellectual Property Rights</h4>
            The brand names relating to the Application and any other
            trademarks, service marks and/or trade names used by us either on
            SaaSPay’s own behalf, or on behalf of its licensors, affiliates and
            partners, are owned by SaaSPay and the relevant others, (the “Trade
            Marks”). In addition to the intellectual property rights in the
            Trade Marks, SaaSPay and its relevant partners own the rights in all
            other content of the Application (the "Content"). By using the
            Application and/or the Services, You shall not obtain any rights in
            the Trade Marks or the Content and You may only use the Trade Marks
            and Content in accordance with the Terms.
            <br />
            <br />
            <h4>Governing Law and Dispute Resolution</h4>
            These Terms shall be governed by, and construed in accordance with,
            the laws of India, without reference to principles of conflicts of
            law. The parties agree that the courts of Bangalore shall have
            exclusive jurisdiction over any dispute arising from or relating to
            the Terms and/or the Application, irrespective of any defense that
            You may raise in relation to forum non-convenience.
            <br />
            <br />
            <h4>Contact Us</h4>
            You can contact SaaSPay's customer support team via email at
            support@saaspay.app and we will resolve your issue at the earliest.
            <br />
            <br />
            SaaSPay's customer care support team is available from:
            <br />
            <b>Monday to Saturday - Between 10 am to 7 pm</b>
            <br />
            <br />
            Also, as required under applicable guidelines issued by Reserve Bank
            of India (RBI) and Information Technology Act, 2000 and rules made
            there under and for the purposes of redressing any complaints, you
            may contact our nodal grievance redressal officer whose name and
            contact details are provided below:
            <br />
            <b>Name</b> - Mukund Makhija
            <br />
            <b>Address</b> - 5th Floor, No 90, Industrialist Area, GR Icon,
            Jyoti Nivas College Rd, 5th Block, Koramangala, Bengaluru,
            Karnataka 560034
            <br />
            <b>Mobile No</b> - +91 8447330060
            <br />
            <b>Email id</b> - mukund@saaspay.app
            <br />
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
}
